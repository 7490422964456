div {
  box-sizing: border-box
}

.App {
  display: flex;
  text-align: center;
  max-height: 100%;
  height: 100%;
  flex-direction: column;
}

.SystemInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  flex-grow: 1;
}

.SystemInfoItem {
  display: flex; /* Grid for individual items */
  flex-direction: row;
  flex-grow: 1;
}

.SystemInfoItem > div:first-child {
  display: flex;
  justify-self: end; /* Align the first column content to the right */
  width: 10rem;
}

.SystemInfoItem > div:last-child {
  display: flex;
  flex-grow: 1;
  justify-self: end; /* Align the first column content to the right */
}

.ChatBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 800px;
  margin: 0 auto;
}

.Controls {
  display: flex;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  overflow-y: auto;
  padding: 10px;
  flex-direction: column;
  margin-left: 10px;
  box-sizing: border-box;
  overflow-x: visible;
  min-width: 10rem;
  width: 100%;
  flex-grow: 1;
}

@media (min-width: 768px) {
  .Controls {
    width: 600px; /* or whatever you prefer for a desktop */
    max-width: 80vw; /* Optional: Prevent it from taking up too much space */
  }
}

.Conversation {
  display: flex;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  flex-direction: column;
  height: 100%;
}

.user-message.MuiCard-root {
  background-color: #DCF8C6;
  border: 1px solid #B2E0A7;
  color: #333333;
  margin-bottom: 0.75rem;
  margin-left: 1rem;
  border-radius: 0.25rem;
  min-width: 80%;
  max-width: 80%;
  justify-self: right;
  display: flex;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  flex-direction: column;
  align-items: self-end;
  align-self: end;
  flex-grow: 0;
}

.assistant-message.MuiCard-root {
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  color: #333333;
  margin-bottom: 0.75rem;
  margin-right: 1rem;
  min-width: 70%;
  border-radius: 0.25rem;
  justify-self: left;
  display: flex;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  flex-direction: column;
  flex-grow: 0;
  padding: 16px 0;
  font-size: 0.9rem;
}

.assistant-message .MuiCardContent-root {
  padding: 0 16px !important;
  font-size: 0.9rem;
}

.assistant-message span {
  font-size: 0.9rem;
}

.user-message .MuiCardContent-root:last-child,
.assistant-message .MuiCardContent-root:last-child {
  padding: 16px;
}

.users > div {
  padding: 0.25rem;
}

.user-active {
  font-weight: bold;
}

.metadata {
  border: 1px solid #E0E0E0;
  font-size: 0.75rem;
  padding: 0.125rem;
}

/* Reduce general whitespace in markdown content */
.assistant-message p.MuiTypography-root {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

/* Reduce space between headings and content */
.assistant-message h1.MuiTypography-root,
.assistant-message h2.MuiTypography-root,
.assistant-message h3.MuiTypography-root,
.assistant-message h4.MuiTypography-root,
.assistant-message h5.MuiTypography-root,
.assistant-message h6.MuiTypography-root {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

/* Reduce space in lists */
.assistant-message ul.MuiTypography-root,
.assistant-message ol.MuiTypography-root {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.assistant-message li.MuiTypography-root {
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}

.assistant-message .MuiTypography-root li {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 0.9rem;
}

/* Reduce space around code blocks */
.assistant-message .MuiTypography-root pre {
  border: 1px solid #F5F5F5;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.9rem;
}
